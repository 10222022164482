import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'System Administration',
                icon: 'pi pi-fw pi-cog',
                items: [
                    {
                        label: 'System Control',
                        icon: 'pi pi-fw pi-cog',
                        routerLink: ['/system-administration/system-control']
                    },
                    {
                        label: 'Respondent Control',
                        icon: 'pi pi-fw pi-building',
                        routerLink: ['/system-administration/respondent-control']
                    },
                    {
                        label: 'Roll Over Reports',
                        icon: 'pi pi-fw pi-file-o',
                        routerLink: ['/system-administration/roll-over-report']
                    },
                    {
                        label: 'Core Posting',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['/system-administration/core-posting']
                    },
                    {
                        label: 'Participant Daily Extract',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['/system-administration/participant-daily-extract']
                    }
                ]
            },
            {
                label: 'Configuration',
                icon: 'pi pi-fw pi-users',
                items: [
                    {
                        label: 'General',
                        icon: 'pi pi-fw pi-users',
                        submenu: true,
                        items: [
                            {
                                label: 'Correspondents',
                                icon: 'pi pi-fw pi-users',
                                routerLink: ['/configuration/general/correspondent'],
                            },
                            {
                                label: 'Holiday Schedule',
                                icon: 'pi pi-fw pi-calendar',
                                routerLink: ['/configuration/general/holiday-schedule'],
                            },
                            {
                                label: 'Archive Credit Transfer',
                                icon: 'pi pi-fw pi-building',
                                routerLink: ['/configuration/general/archive-credit-transfer'],
                            }
                        ]
                    },
                    {
                        label: 'FedNow Settings',
                        icon: 'pi pi-fw pi-key',
                        submenu: true,
                        items: [
                            {
                                label: 'FedNow Public MQ Certificates',
                                icon: 'pi pi-fw pi-sun',
                                routerLink: ['/configuration/fednow-settings/fednow-public-mq-certificates'],
                            },
                            {
                                label: 'Aptys Public Keys',
                                icon: 'pi pi-fw pi-key',
                                routerLink: ['/configuration/fednow-settings/aptys-public-key'],
                            },
                            {
                                label: 'FedNow Public Keys',
                                icon: 'pi pi-fw pi-key',
                                routerLink: ['/configuration/fednow-settings/fednow-public-key'],
                            },
                            {
                                label: 'CCDT File',
                                icon: 'pi pi-fw pi-file-o',
                                routerLink: ['/configuration/fednow-settings/ccdt-file']
                            },
                            {
                                label: 'Client Certificate',
                                icon: 'pi pi-fw pi-sun',
                                routerLink: ['/configuration/fednow-settings/client-certificate']
                            },
                            {
                                label: 'Outgoing Queue Mappings',
                                icon: 'pi pi-fw pi-sign-out',
                                routerLink: ['/configuration/fednow-settings/outgoing-queue-mapping']
                            },
                            {
                                label: 'FedNow Mq Queue Listeners',
                                icon: 'pi pi-fw pi-sign-in',
                                routerLink: ['/configuration/fednow-settings/fednow-incoming-queue-listeners']
                            },
                            {
                                label:'Connection Party',
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: ['/configuration/fednow-settings/fednow-connection-party-configuration']
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Notifications',
                icon: 'pi pi-fw pi-bell',
                items: [
                    {
                        label: 'Notifications Summary',
                        icon: 'pi pi-fw pi-bell',
                        routerLink: ['/notification/notification-summary']
                    }
                ]
            }
        ];
    }
}
